// set in website/template/includes/vue.html
import * as Sentry from "@sentry/browser"

import { parseObject } from "utils/parsers"


// --- break out an exception into its parts,
//     or send along the error if we are forcing an error/message
const sentryReporter = ( error ) => {
    if ( process.env.NODE_ENV === "production" ) {
        const {
            code,
            message
        } = error

        if ( code && message ) {
            Sentry.captureException( `Error Code: ${ code }; Message: ${ message }` )
        }
        else Sentry.captureException( error )
    }
}

// Vue automatically calls "install" for plugins
const SentryHandler = {
    // allow us to manually report an exception
    // inside of Vue (try/catch blocks) by using this.$sentry(<error>)
    install ( Vue, options ) {
        if ( process.env.NODE_ENV === "production" ) {
            const sentryOptions = {
                dsn: options.dsn,
                integrations: [ new Sentry.Integrations.Vue({ Vue }) ]
            }
            const GLOBAL_DATA = parseObject({ id: "GLOBAL_DATA" })

            if ( GLOBAL_DATA.SENTRY_RELEASE_VERSION ) {
                sentryOptions.release = GLOBAL_DATA.SENTRY_RELEASE_VERSION
            }

            Sentry.init( sentryOptions )

            Vue.prototype.$sentry = ( error ) => sentryReporter( error )
        }
    },

    // allow us to manually report an exception outside of Vue
    report: sentryReporter
}


Vue.use( SentryHandler, {
    dsn: "https://2c3fea677bac4cf9b0e6c71342862209@sentry.io/1386638"
})
