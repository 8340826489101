// --- batchConvertCasing

export default function batchConvertCasing ( payload, casingFn ) {
    function objectReducer ( procesedPayload, [ key, value ]) {
        procesedPayload[casingFn( key )] = value

        return procesedPayload
    }

    return Array.isArray( payload )
        ? payload
            .map( casingFn )
        : Object
            .entries( payload )
            .reduce( objectReducer, {})
}