import { stripHtmlTags } from "utils/strings"

export default function parseTitle ( rawValue ) {
    function parseValue ( value ) {
        try {
            const data = JSON.parse( value )

            return data.title || data.alt || data.url || value
        }
        catch ( e ) {
            return value
        }
    }

    const title = parseValue( rawValue )

    return stripHtmlTags( title )
}
