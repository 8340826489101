// --- slugify


export default function slugify ( str, options = {}) {
    if ( !str ) return str

    const {
        spacer = "-"
    } = options

    return str
        .toString()
        .toLowerCase()
        .replace( /\s+/g, spacer ) // Replace spaces
        // eslint-disable-next-line no-useless-escape
        .replace( /[^\w\-\_]+/g, "" ) // Remove all non-word chars
        .replace( /(_)+/g, "_" ) // Replace multiple underscores
        .replace( /(-)+/g, "-" ) // Replace multiple dashes
        .replace( /^(_|-)+/, "" ) // Trim separators from start of text
        .replace( /(_|-)+$/, "" ) // Trim separators from end of text if input manually
}