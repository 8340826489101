// --- parseObject utility function
// used to parse json data embedded in app rendered template

import isObject from "utils/objects/isObject.js"

export default function parseObject ({ id, required = false, replaceEmpty = false }) {
    try {
        const element = document.getElementById( id )

        // we don't have this in the template
        // if required, lets throw an error
        // else fail silently as we might just have it as an optional load
        if ( !element && required ) {
            throw `
                [ButterCMS] Template data of #${ id }
                is not in template and is marked as required.
            `
        }

        if ( !element ) return {}
        const result = JSON.parse( element.textContent )

        // using {{ <python object>|json_script:"<id>" }} in Django template
        // needs a double parse. This is a result of Django using json.dumps
        // in the backend. As usage of `json.dumps` may vary across the Django
        // application, this serves as a validity check.
        return isObject( result ) || Array.isArray( result )
            ? result
            : JSON.parse( result )
    }
    catch ( error ) {
        console.error( error )

        return required ? false : {}
    }
}
