// --- formatStringIteration
// list strings comma separated and the last string separated by and
// e.g. formatStringIteration(['a', 'b', 'c', 'd']) === 'a, b, c and d'
// e.g. formatStringIteration(['a', 'b']) === 'a and b'

export default function formatStringIteration ( strings ) {
    function reducer ( res, string, index ) {
        if ( !index ) {
            return string
        }

        if ( index <= strings.length - 2 ) {
            return `${ res }, ${ string }`
        }

        return `${ res } and ${ string }`
    }

    return strings
        .reduce(
            reducer,
            ""
        )
}