// --- truncate

import isString from "./isString.js"

export default function truncate ( str, n, useWordBoundary ) {
    if ( !isString( str ) ) { return "" }

    if ( str.length <= n ) {
        return str
    }

    const subString = str.substring( 0, n )

    return useWordBoundary
        ? subString.substring( 0, subString.lastIndexOf( " " ) )
        : subString
}