// --- parseBooleans

export default function parseBooleans ( obj ) {
    const stringObj = JSON
        .stringify( obj )
        .replace(
            ':"false"',
            ":false"
        )
        .replace(
            ':"true"',
            ":true"
        )

    return JSON.parse( stringObj )
}