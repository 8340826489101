// --- parseAppRoutes utility function
// used to parse app URL routes embedded in app rendered template

import parseObject from "./parseObject.js"


// certain Django routes require a data id to be declared.
// since our data is dynamic, we can override this
// by supplying a '0' and replacing it with a named param in JS
const placeholderReplacement = /(\/0{1})(?=[^\d\w_])/g
const endReplacement = /(\/0{1})$/g


export default function parseAppRoutes ({
    id,
    replaceEmpty = false,
    required = false,
}) {
    try {
        const refined = parseObject({
            id,
            replaceEmpty,
            required,
        })

        return Object.entries( refined )
            .reduce(
                ( routeObj, [ key, route ]) => {
                    if ( typeof ( route ) === "string" ) {
                        routeObj[key] = route
                            .replace(
                                placeholderReplacement,
                                replaceEmpty || "/:id"
                            )

                        routeObj[key] = routeObj[key]
                            .replace(
                                endReplacement,
                                replaceEmpty || "/:id"
                            )
                    }

                    return routeObj
                },
                {}
            )
    }
    catch ( error ) {
        return required ? false : {}
    }
}