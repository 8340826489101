// --- String utility functions

export { default as capInitial } from "./capInitial.js"
export { default as CSVIncludes } from "./CSVIncludes.js"
export { default as escapeForRegExp } from "./escapeForRegExp.js"
export { default as formatStringIteration } from "./formatStringIteration.js"
export { default as getNounSingularPluralEnding } from "./getNounSingularPluralEnding.js"
export { default as getVerbSingularPluralEnding } from "./getVerbSingularPluralEnding.js"
export { default as isEmptyString } from "./isEmptyString.js"
export { default as isString } from "./isString.js"
export { default as slugify } from "./slugify.js"
export { default as stripHtmlTags } from "./stripHtmlTags.js"
export { default as truncate } from "./truncate.js"